<template>
  <v-card flat>
    <v-card-text>
      <apex-chart
        ref="chart"
        :key="chartId"
        :options="defaultOptions"
        :series="data.series"
        type="donut"
        :height="height - 400"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { useWindowSize } from '@vueuse/core'
import { useFilterStore } from '@/stores/FilterStore'
import { apiService } from '@/api'
import { currencyConvert, getMfrColors, getMfrNames } from '@/util/functions'
import themeConfig from '@themeConfig'
import { ref } from 'vue'

export default {
    name: 'PieChartDashboard',
    props: {
        seriesName: {
            type: String,
            default: 'series',
        },
        genericProductNumber: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        const chartId = ref(0)
        const data = ref([])
        const { width, height } = useWindowSize()
        const $themeColors = themeConfig.themes.light
        const filterStore = useFilterStore()
        const defaultOptions = {
            title: {
                text: 'Manufacturer Market Share Pie Chart',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: $themeColors.primary,
                },
            },
            legend: {
                show: true,
            },
            dataLabels: {
                enabled: true,
                offsetX: 30,
                style: {
                    fontSize: '10px',
                    fontWeight: 'bold',
                },
                formatter(value) {
                    return `%${parseInt(value * 100, 10) / 100}`
                },
            },
            tooltip: {

                y: {
                    formatter(value) {
                        return currencyConvert(value)
                    },
                },
            },
            labels: [],
            chart: {
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                        customIcons: [],
                    },
                    export: {
                        csv: {
                            filename: undefined,
                            columnDelimiter: ',',
                            headerCategory: 'category',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString()
                            },
                        },
                        svg: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        },
                    },
                    autoSelected: 'zoom',
                },
                type: 'donut',
                sparkline: {
                    enabled: false,
                },
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        // router.app.$root.$emit('mfr-market-share-pie-chart', config)
                        console.log(config)
                    },
                },
            },
            noData: {
                text: 'No Data',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: $themeColors.primary,
                    fontSize: '20px',

                    //   fontFamily: undefined,
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        expandOnClick: false,
                        size: '70%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '6px',
                                color: $themeColors.primary,
                                formatter(val) {
                                    return getMfrNames(val)
                                },
                            },
                            value: {
                                show: true,
                                fontSize: '15px',
                                fontWeight: 600,
                                formatter(value) {
                                    return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
                                },
                            },
                            total: {
                                show: true,
                                label: 'Drug Costs',
                                color: 'rgba(94, 86, 105, 0.68)',
                                formatter(value) {
                                    return `${currencyConvert(
                                        parseInt(value.globals.seriesTotals.reduce((total, num) => total + num) * 100, 10) / 100,
                                    )}`
                                },
                            },
                        },
                    },
                },
            },
            states: {
                active: {
                    allowMultipleDataPointsSelection: true,
                    filter: {
                        type: 'darken',
                        value: 0.35,
                    },
                },
            },
        }

        return {
            data,
            chartId,
            height,
            width,
            getMfrNames,
            getMfrColors,
            defaultOptions,
            filterStore,
            currencyConvert,
        }
    },
    watch: {
        genericProductNumber: {
            handler() {
                this.getMongoChartData()
            },
            deep: true,
        },
    },
    created() {
        this.$root.$on('refreshChart', () => {
            this.getMongoChartData()
        })
    },
    async mounted() {
        this.getMongoChartData()
    },
    destroyed() {
        this.$root.$off('refreshChart')
    },
    methods: {
        async getMongoChartData() {
            this.data = await apiService.getData(this.seriesName, {})
            const colors = await this.getMfrColors(this.data.labels)
            this.defaultOptions = {
                ...this.defaultOptions,
                labels: this.data.labels,
                colors,
            }
            this.chartId += 1
        },
    },
}
</script>
